export class HttpError extends Error {
    url;
    statusCode;
    request;
    response;
    responseBody;
    constructor(url, statusCode, statusText, request, response, responseBody) {
        super(statusText);
        this.name = "HttpError";
        this.statusCode = statusCode;
        this.response = response;
        this.request = request;
        this.url = url;
        this.responseBody = responseBody;
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HttpError);
        }
        Object.setPrototypeOf(this, HttpError.prototype);
    }
}
