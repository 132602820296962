import { HttpError } from "./http-error.js";
export const createHttpClient = (baseUrl, headers, fetchImpl) => {
    const joinUrls = (baseUrl, path) => {
        // Return path if no baseUrl is set.
        if (baseUrl === undefined || baseUrl.length === 0) {
            return path;
        }
        // Ensure the first string doesn't end with a slash
        if (baseUrl.endsWith("/")) {
            baseUrl = baseUrl.slice(0, -1);
        }
        // Ensure the second string doesn't start with a slash
        if (path.startsWith("/")) {
            path = path.slice(1);
        }
        // Don't concatenate with a single slash if path is query params.
        if (path.startsWith("?")) {
            return baseUrl + path;
        }
        // Concatenate with a single slash
        return baseUrl + "/" + path;
    };
    const createRequest = (url, method) => {
        const pReq = {
            absoluteUrl: joinUrls(baseUrl ?? "", url),
            method: method,
            headers: headers ?? {},
            body: null,
        };
        pReq.setHeaders = (headers) => {
            pReq.headers = headers;
            return pReq;
        };
        pReq.appendHeader = (headers) => {
            pReq.headers = {
                ...pReq.headers,
                ...headers,
            };
            return pReq;
        };
        pReq.setCredentials = (credentials) => {
            pReq.credentials = credentials;
            return pReq;
        };
        pReq.setRedirect = (redirect) => {
            pReq.redirect = redirect;
            return pReq;
        };
        pReq.setBody = (body, mimeType) => {
            pReq.body = body;
            if (mimeType && pReq.appendHeader) {
                pReq.appendHeader({ "Content-Type": mimeType });
            }
            return pReq;
        };
        pReq.send = async () => sendRequest(pReq);
        return pReq;
    };
    const sendRequest = async (request) => {
        const response = await (fetchImpl ?? fetch)(request.absoluteUrl, {
            method: request.method,
            headers: request.headers,
            body: request.body,
            credentials: request.credentials,
            redirect: request.redirect,
        });
        if (!response || response.type == "error") {
            throw new HttpError(request.absoluteUrl, 0, "No response", request);
        }
        if (!response.ok && !(response.type === "opaque" || response.type === "opaqueredirect")) {
            const responseBody = await response.clone().text();
            throw new HttpError(request.absoluteUrl, response.status, response.statusText, request, response, responseBody);
        }
        return response;
    };
    return {
        get: (url) => createRequest(url, "GET"),
        post: (url) => createRequest(url, "POST"),
        put: (url) => createRequest(url, "PUT"),
        delete: (url) => createRequest(url, "DELETE"),
        patch: (url) => createRequest(url, "PATCH"),
        options: (url) => createRequest(url, "OPTIONS"),
        head: (url) => createRequest(url, "HEAD"),
    };
};
