export const parseCookies = () => {
    const cookies = document.cookie
        .split(";")
        .reduce((ac, cv) => {
        const [cookieKey, cookieValue] = cv.split("=").map((str) => str.trim());
        return Object.assign(ac, { [cookieKey]: cookieValue });
    }, {});
    return cookies;
};
export const getCookieYesConsent = (key, consentCookie) => {
    if (!consentCookie) {
        const cookies = parseCookies();
        consentCookie = cookies["cookieyes-consent"];
    }
    if (!consentCookie)
        return false;
    const { [key]: value } = consentCookie.split(",").reduce((obj, pair) => {
        const [k, v] = pair.split(":");
        obj[k.trim()] = v.trim();
        return obj;
    }, {});
    return value == "yes";
};
